import React from 'react'
import styled from '@emotion/styled'
import { fonts, colors } from '../../utils/styles'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'

const StSubHeadlineLink = styled(Link)`
  font-family: ${fonts.bold};
  color: ${colors.darkGray};
  font-size: 1.5em;
  text-decoration: none;
  padding: 8px 0 5px;
  border-bottom: 2px solid ${colors.lemonadeYellow};
  cursor: pointer;
  width: fit-content;
`

const StFlexGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  width: fit-content;
  gap: 0.5rem;
`

const StInfoSpan = styled.span`
  font-size: 1em;
  white-space: pre-line;
`

const NewsletterInfo = () => {
  const { t } = useTranslation('misc')

  return (
    <StFlexGroup>
      <StSubHeadlineLink to={'/newsletter'}>{t('newsletterInfoLink.headline')}</StSubHeadlineLink>
      <StInfoSpan>{t('newsletterInfoLink.tenPercentOff')}</StInfoSpan>
    </StFlexGroup>
  )
}

export default NewsletterInfo
